import React, { useState, useContext } from 'react';
import { NavLink, withRouter } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import logo from '../css/images/logo.png'
import ModalLogin from './ModalLogin';
import { UserContext } from '../App';


function Menu() {
    const [modalShow, setModalShow] = useState(false);
    const [user, setUser] = useContext(UserContext);

    let button;
    let documentsSection;

    if (user.logged === true) {
        button = <span onClick={() => setUser({ logged: false })} >Salir</span>;
        documentsSection = <Nav.Link as={NavLink} to="/documentos" >documentos</Nav.Link>
    } else {
        button = <Button variant="primary" onClick={() => setModalShow(true)}>Login</Button>;
    }

    return (
        <Container>
            <Navbar collapseOnSelect expand="lg">
                <Navbar.Brand as={NavLink} to="/" ><img src={logo} alt="logo trustsurvey"></img></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto d-flex align-items-center">
                        <Nav.Link as={NavLink} exact to="/">inicio</Nav.Link>
                        <Nav.Link as={NavLink} to="/contexto" >contexto</Nav.Link>
                        <Nav.Link as={NavLink} to="/objetivos" >objetivos</Nav.Link>
                        <Nav.Link as={NavLink} to="/participantes" >participantes</Nav.Link>
                        {documentsSection}
                        <Nav.Link>
                            {button}
                            <ModalLogin
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    );
}

export default withRouter(Menu);