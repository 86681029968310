import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import RightColumn from './RightColumn';
import BreadCrumbApp from './BreadCrumbApp';
import patrocinador3 from '../css/images/patrocinador3.png'
import patrocinador4 from '../css/images/patrocinador4.png'



function Participants(props) {
    return (
        <div className="participants">
            <BreadCrumbApp section={props.section}></BreadCrumbApp>
            <Container>
                <Row className="mt-5">
                    <Col xs={12} md={9}>
                        <Row>
                            <Col xs={12} sm={6} className="text-center">
                                <img src={patrocinador3} alt="participant" className="patrocinador p-3 mb-3" />
                                <br />
                                <a href="http://www.eurostarmediagroup.es" className="font-blue" target="_blank" rel="noopener noreferrer">
                                    www.eurostarmediagroup.es
                                </a>
                            </Col>
                            <Col xs={12} sm={6} className="text-center">
                                <img src={patrocinador4} alt="participant" className="patrocinador p-3 mb-3" />
                                <br />
                                <a href="http://www.usal.es" className="font-blue" target="_blank" rel="noopener noreferrer">
                                    www.usal.es
                                </a>
                            </Col>
                        </Row>
                    </Col>
                    <RightColumn></RightColumn>
                </Row>
            </Container>
        </div >
    );
}

export default Participants;