import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import RightColumn from './RightColumn';
import BreadCrumbApp from './BreadCrumbApp';



function Context(props) {
    return (
        <div className="home">
            <BreadCrumbApp section={props.section}></BreadCrumbApp>
            <Container>
                <Row className="mt-5">
                    <Col xs={12} md={9}>
                        <p>
                            En el siglo XX, el trabajo de los politólogos y pioneros en la investigación de la opinión pública, como George Horace Gallup, fue una respuesta a la creciente demanda de las instituciones públicas para obtener información sobre la sociedad. Las encuestas proporcionaron datos y patrones de comportamiento que fueron utilizados por los distintos gobiernos para diseñar sus políticas. Sin embargo, las sociedades contemporáneas ya no son entidades centradas en la industria que dependen de la producción de bienes y servicios necesarios para la supervivencia y la comodidad. Por el contrario, nuestra sociedad está impulsada por algo que le da su propio nombre: la sociedad de la información. Comprender cómo funciona nuestra sociedad basada en la información requiere un flujo rápido y preciso de, precisamente, información sobre preferencias, necesidades y comportamientos. Esta necesidad crítica de información por parte de los gobiernos, las empresas y las instituciones sociales explica por qué se confía tanto en las encuestas. Las encuestas y estudios de opinión dicen, por ejemplo, qué proporción de una población tiene un punto de vista específico y, por tanto, ayudan a responder muchas preguntas que puede tener una empresa como posibles clientes, toma de decisiones, además de permitir comparar estadísticas a tiempo.
                        </p>

                        <p>
                            Con el fin de contribuir a encontrar una alternativa a los procedimientos y métodos actuales, transformar el sector actualizándolo a la nueva era digital, el proyecto TRUSTSURVEY pretende combinar los valiosos métodos y conocimientos de un sector maduro como es el de las encuestas tradicionales, con los sectores en crecimiento de análisis de datos, procesamiento del lenguaje natural y Deep learning, para eliminar el sesgo en las encuestas, y poder ofrecer una encuesta verificada.
                        </p>
                    </Col>
                    <RightColumn></RightColumn>
                </Row>
            </Container>
        </div>
    );
}

export default Context;