import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import RightColumn from './RightColumn';
import BreadCrumbApp from './BreadCrumbApp';
import Pdf1 from '../docs/E2.1 Documento de requisitos funcionales y técnicos v1.0.0.pdf';
import Pdf2 from '../docs/E2.2 Documento de modelo de arquitectura v1.0.0.pdf';



function Documents(props) {
    return (
        <div className="documents">
            <BreadCrumbApp section={props.section}></BreadCrumbApp>
            <Container>
                <Row className="mt-5">
                    <Col xs={12} md={9}>
                        <Row>
                            <Col>
                                <a href={Pdf1} target="_blank" rel="noopener noreferrer">E2.1 Documento de requisitos funcionales y técnicos v1.0.0</a><br />
                                <a href={Pdf2} target="_blank" rel="noopener noreferrer">E2.2 Documento de modelo de arquitectura v1.0.0.pdf</a>
                            </Col>
                        </Row>
                    </Col>
                    <RightColumn></RightColumn>
                </Row>
            </Container>
        </div >
    );
}

export default Documents;