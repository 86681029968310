import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Jumbotron from './Jumbotron';
import RightColumn from './RightColumn';



function AppRouter() {
    return (
        <div className="home">
            <Jumbotron></Jumbotron>
            <Container>
                <Row>
                    <Col xs={12} sm={9}>
                        <p>El proyecto <b>TRUSTSURVEY</b> (Inteligencia Social y Minería de Opinión para la Adaptación de Encuestas y Estudios de Mercado a las Nuevas Tecnologías) pretende desarrollar una plataforma Big Data con el objetivo de procesar fuentes heterogéneas de datos para asegurar la validez y fiabilidad de las encuestas de opinión online. Para lograr este objetivo, la plataforma deberá minar diversas fuentes de datos en tiempo real, integrando este proceso en un entorno Big Data de cómputo distribuido. Tras la integración de datos y su almacenamiento, la plataforma deberá aplicar diversas técnicas de los campos del procesamiento del lenguaje natural, inteligencia artificial y reconocimiento de patrones, para analizar la opinión que los usuarios encuestados manifiestan en sus redes sociales y contrastarlas con las respuestas en las encuestas online.</p>

                        <p>En particular, la plataforma se basará principalmente en el análisis de tres modalidades de información para el análisis de opiniones online: <br />
                            (1) La identificación de cuentas sociales clave, con una gran capacidad de modificar las opiniones de terceros y cuyo alineamiento ideológico puede ser indicativo para descubrir el alineamiento de sus seguidores, son usuarios conocidos como influencers. <br />
                            (2) La detección de comunidades de usuarios, que permitirá explotar la propiedad homofílica de las redes sociales para identificar comunidades de individuos con opiniones similares, agrupando a los usuarios en base a sus redes de seguidores mediante los algoritmos de detección de comunidades. <br />
                            (3) El contenido semántico de los mensajes publicados por los usuarios encuestados en las redes sociales, que permitirá mediante la aplicación de técnicas del procesamiento del lenguaje natural validar la fiabilidad de las respuestas proporcionadas por los usuarios encuestados al contrastar estas con los resultados de algoritmos de</p>
                    </Col>
                    <RightColumn></RightColumn>
                </Row>
            </Container>
        </div>
    );
}

export default AppRouter;