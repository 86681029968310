import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function BreadCrumbApp(props) {
    return (
        <div className="banner-breadcrumb bg-blue py-5">
            <Container>
                <Row>
                    <Col>
                        <span className="text-uppercase font-weight-bold font-size-3">{props.section}</span>
                    </Col>
                    <Col className="text-right">
                        Inicio /
                        <span>{props.section}</span>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default BreadCrumbApp;