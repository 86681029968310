export default function validate(values) {
    let errors = {};

    if (!values.email) {
        errors.email = 'Escriba su email';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Dirección de email invalida';
    }

    if (!values.password) {
        errors.password = 'Escriba su contraseña';
    } else if (values.password.length < 8) {
        errors.password = 'La contraseña debe contener 8 o más caracteres';
    }

    return errors;
};