import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import patrocinador1 from '../css/images/patrocinador1.png'
import patrocinador2 from '../css/images/patrocinador2.png'
import patrocinador5 from '../css/images/patrocinador5.png'


function RightColumn() {
    return (
        <Col xs={12} md={3} className="font-small bl-blue py-3">
            <p>El proyecto <b>TRUSTSURVEY</b> (Inteligencia Social y Minería de Opinión para la Adaptación de Encuestas y Estudios de Mercado a las Nuevas Tecnologías) con el número de expediente RTC-2017-6157-6 ha sido financiado por el Ministerio de Economía, Industria y Competitividad y la Agencia Estatal de Investigación, y cofinanciada con Fondos Estructurales de la Unión Europea, conforme a lo establecido en el artículo 31 de la Resolución de 27 de octubre de 2017, para la concesión de las ayudas correspondientes a la convocatoria RETOS- COLABORACIÓN.</p>
            <p><b>FINANCIACIÓN TOTAL:</b> 504.527,29€ <br />
                <b>DURACIÓN:</b> 2018-2019
                        </p>
            <Row>
                <Col className="text-center">
                    <img src={patrocinador1} alt="participant" />
                </Col>
            </Row>
            <Row>
                <Col className="text-center mt-3">
                    <img src={patrocinador5} alt="participant" />
                </Col>
            </Row>
            <Row>
                <Col className="text-center mt-3">
                    <img src={patrocinador2} alt="participant" />
                </Col>
            </Row>
            <Row>
                <Col className="mt-3">
                    “Promover el desarrollo tecnológico, la innovación y una investigación de calidad”
                </Col>
            </Row>
        </Col>
    );
}

export default RightColumn;