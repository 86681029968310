import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import useForm from "../components/useForm";
import validate from '../components/LoginFormValidationRules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { UserContext } from '../App';

function ModalLogin(props) {

    const [cardError, setCardError] = useState({ visible: 'false', message: '' });
    const [isLoading, setIsLoading] = useState(false);

    const {
        values,
        errors,
        handleChange,
        handleSubmit
    } = useForm(Login, validate);

    const [, setUser] = useContext(UserContext);



    async function Login() {

        try {


            const newMessageObj = {
                visible: false, errorMessage: ""
            };
            setCardError(newMessageObj);
            setIsLoading(true);

            let formData = new FormData();
            formData.append('email', values.email);
            formData.append('password', values.password);


            const response = await fetch('http://www.trustsurvey.es/api/v1/login.php', {
                method: 'POST',
                body: formData,
                headers: {
                }
            });
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const data = await response.json();

            if (data.ok === false) {

                //open the card and put the error on it
                const newMessageObj = { visible: true, errorMessage: data.msg };
                setCardError(newMessageObj);
            } else {
                //set the user as logged
                setUser({ logged: true });

                //empty the inputs from the form
                values.email = "";
                values.password = "";

                //close the modal
                props.onHide();

            }
        } catch (error) {
            console.log(error);
        }

        setIsLoading(false);
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Inicie sesión
          </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control className={`input ${errors.email && 'is-invalid'}`}
                            type="email"
                            name="email"
                            onChange={handleChange}
                            value={values.email || ''}
                            required />
                        {errors.email && (
                            <div className="invalid-feedback">{errors.email}</div>
                        )}
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control className={`input ${errors.password && 'is-invalid'}`}
                            type="password"
                            name="password"
                            onChange={handleChange}
                            value={values.password || ''}
                            required />
                        {errors.password && (
                            <div className="invalid-feedback">{errors.password}</div>
                        )}
                    </Form.Group>
                    <Card bg="danger" text="white" style={{ width: '18rem' }} className={`my-1 w-100 text-center ${cardError.visible === true ? ' ' : 'd-none'}`}>
                        <Card.Body>
                            <Card.Text>
                                {cardError.errorMessage}
                                {cardError.visible}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Button variant="primary" type="submit" block onClick={handleSubmit} >
                        {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Submit'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal >
    );
}

export default ModalLogin;