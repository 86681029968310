import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'


function JumbotronHome() {
    return (
        <Jumbotron>
            <Container>
                <h1>TRUSTSURVEY</h1>
                <p className="lead">
                    Inteligencia Social y Minería de Opinión para la Adaptación de Encuestas y Estudios de Mercado a las Nuevas Tecnologías
            </p>
            </Container>
        </Jumbotron>
    );
}

export default JumbotronHome;