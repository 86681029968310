import React from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import RightColumn from './RightColumn';
import BreadCrumbApp from './BreadCrumbApp';



function Objetives(props) {
    return (
        <div className="home">
            <BreadCrumbApp section={props.section}></BreadCrumbApp>
            <Container>
                <Row className="mt-5">
                    <Col xs={12} md={9}>
                        <p>
                            <b>1º. Dotar de un porcentaje de veracidad por encima del 95% a las respuestas en las encuestas mediante el uso de minería de datos e inteligencia artificial. </b><br />
                            Se enriquecerán las encuestas online mediante la minería de datos a partir de mensajes publicados en las redes sociales.</p>

                        <p><b>2º. Desarrollar una plataforma para la verificación de respuestas en encuestas online mediante la minería de opinión en redes sociales.</b> <br />
                            TrustSurvery será una plataforma basada en el paradigma Big Data que incorporará técnicas de minería de datos y procesamiento del lenguaje natural para asegurar la fiabilidad de las encuestas realizadas.</p>

                        <p><b>3º. Mostrar el valor añadido de los resultados de las encuestas online gracias al aumento de su fiabilidad logrado por nuestra plataforma. </b><br />
                            Se pondrá de manifiesto la mejora en la fiabilidad, reducción de sesgo y aplicabilidad de la plataforma.</p>

                        <p><b>4º. Probar y refinar las herramientas diseñadas en entornos de aplicación realistas. </b><br />
                            Refinamiento y mejora de los algoritmos y componentes de la plataforma en base a su validación en entornos realistas.</p>

                        <p><b>5º. Demostrar la rentabilidad de la plataforma propuesta para su comercialización como SaaS. </b><br />
                            Se evalúa la viabilidad de la explotación de la plataforma con un modelo Software as a Service
                        </p>
                    </Col>
                    <RightColumn></RightColumn>
                </Row>
            </Container>
        </div >
    );
}

export default Objetives;