import React, { useState } from 'react';
import './App.css';
import Menu from './pages/Menu';
import Home from './pages/Home';
import Context from './pages/Context';
import Objetives from './pages/Objetives';
import Participants from './pages/Participants';
import Documents from './pages/Documents';
import PrivateRoute from './components/PrivateRoute'
import { HashRouter  as Router, Route, Switch } from "react-router-dom";

export const UserContext = React.createContext();

function App() {
  const [user, setUser] = useState({ logged: false });

  return (
    <UserContext.Provider value={[user, setUser]}>
      <Router>
        <Menu></Menu>
        <Switch>
          <Route path="/contexto/" component={props => <Context {...props} section="contexto" />} />
          <Route path="/objetivos/" component={props => <Objetives {...props} section="objetivos" />} />
          <Route path="/participantes/" component={props => <Participants {...props} section="participantes" />} />
          <PrivateRoute path="/documentos/" component={props => <Documents {...props} section="documentos" />} />
          <Route path="/" exact component={Home} />
        </Switch>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
